export const useEnvironment = () => {
  const dayJs = useDayjs()
  const currentEnvironemnt = ref(import.meta.env?.VITE_APP_ENV)

  const isProduction = computed(() => {
    return currentEnvironemnt.value === 'production'
  })

  const currentlyInsideProclaimMaintenanceWindow = computed(() => {
    const currentHour = dayJs().hour()

    if (currentHour < 7 || currentHour >= 22) {
      return true
    }

    return false
  })

  return {
    currentEnvironemnt,
    isProduction,
    currentlyInsideProclaimMaintenanceWindow,
  }
}
